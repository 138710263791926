import React, { useState } from 'react';
import styled from 'styled-components';
import { useCart } from '@app/lib/cart';
import { Badge } from './Badge';
import { Form } from './Form';
import { Button } from '@app/components/ui/Button';

export const Coupon: React.FC = () => {
  const { cart } = useCart();
  const [showForm, setShowForm] = useState<boolean>(null);

  return (
    <Container>
      <Button
        block
        type="button"
        variant="text"
        disabled={false}
        onClick={() => setShowForm(!showForm)}
        style={{
          color: '#4b7294',
          fontWeight: '300',
          letterSpacing: '0',
          padding: '0',
        }}
      >
        Have a coupon? Click here to enter your code
      </Button>

      {!cart?.voucher && showForm && <Form cart={cart} />}
      {cart?.voucher && <Badge cart={cart} />}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 1rem;
  margin: 0 0 1.3rem;
  font-size: 1em;
  border: 1px solid #cdd8e4;
  border-radius: 4px;
`;

export default Coupon;
