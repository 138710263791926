import { Cart } from '@app/lib/graphql/models';

export function isCouponBogo(couponCode: string): boolean {
  return /B2G1*/gm.test(couponCode.toUpperCase());
}

export function getBogoErrorMsg(cart: Cart): string {
  let msg = 'Coupon is invalid.';

  switch (cart.totalPrintsQuantity) {
    case 1:
      msg += ' Please add 2 more Moon Maps to use this coupon.';
      break;
    case 2:
      msg += ' Please add an additional Moon Map to use this coupon.';
      break;
    default:
  }

  return msg;
}

export function getCouponErrorMsg(couponCode: string, cart: Cart): string {
  return isCouponBogo(couponCode)
    ? getBogoErrorMsg(cart)
    : 'Coupon is invalid or expired.';
}

export function notValidOnDigital(couponCode: string, cart: Cart): boolean {
  let notValid = false;
  const codesToCheck = [
    'JLHSD67AS',
    'OOM478JL4',
    'TW98JT129',
    'AMYBALLOU',
    'TWJUTLM19',
    'TW98JT129',
    'QWYT4J19',
    'PRINT89025',
  ];

  const hasDigital = cart.hasDigitalPrint;
  const hasPrints = cart.items.some(item => item.sku.includes('P18X24'));
  const hasCode = codesToCheck.some(code => couponCode === code);

  if (!hasPrints && hasDigital && hasCode) {
    notValid = true;
  }

  return notValid;
}
