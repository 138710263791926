import styled from 'styled-components';

export const Alert = styled.div<{ isClickable?: boolean }>`
  margin: 0 0 0.8em;
  padding: 0.8em 1em;
  border-radius: 5px;
  font-size: 0.9rem;
  line-height: 1.5em;
  letter-spacing: 0.2px;
  color: #1d435e;

  ${props => props.isClickable && 'cursor: pointer;'}
`;

export const ErrorAlert = styled(Alert)`
  background: #ffffff;
  border: 1px solid #e42a26;
  color: #b50905;
`;

export const InfoAlert = styled(Alert)`
  background: #fbfdff;
  border: 1px solid #bac9d4;
  color: #487596;
`;

export const SuccessAlert = styled(Alert)`
  background: #ffffff;
  border: 1px solid #3c763d;
`;

export const WarningAlert = styled(Alert)`
  background: #fff5d3;
  border: 1px solid #ecd797;
  color: #775a07;
`;
