import React, { useState } from 'react';
import Router from 'next/router';
import * as Sentry from '@sentry/browser';
import * as Analytics from '@app/lib/analytics';
import styled from 'styled-components';
import { Cart } from '@app/lib/graphql/models';
import { useApplyVoucherMutation } from '@app/lib/graphql/schema';
import { Alert } from '@app/components/ui/Alert';
import { Button } from '@app/components/ui/Button';
import { Input } from '@app/components/ui/Form/Input';
import { media } from '@app/styles';
import {
  isCouponBogo,
  getBogoErrorMsg,
  getCouponErrorMsg,
  notValidOnDigital,
} from './utils';

interface CouponFormProps {
  cart: Cart;
}

function ErrorAlert({ error, code }: { error: string; code: string }) {
  return isCouponBogo(code) ? (
    <Alert
      variant="error"
      style={{ marginTop: 15, cursor: 'pointer' }}
      onClick={() => Router.push('/create')}
    >
      {error} <strong>Click here to create another moon map.</strong>
    </Alert>
  ) : (
    <Alert variant="error">{error}</Alert>
  );
}

export const Form: React.FC<CouponFormProps> = ({ cart }) => {
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string>(null);
  const [addPromoCode, { loading }] = useApplyVoucherMutation();

  const onSubmit = async e => {
    e.preventDefault();
    setError(null);

    const couponCode = code.toUpperCase().trim();
    Analytics.track('couponEntered', cart, couponCode);

    try {
      if (!couponCode) throw new Error('Please enter a coupon.');

      if (notValidOnDigital(couponCode, cart)) {
        setError(
          'Coupon invalid on digital moon maps. Order must include a physical print.'
        );
        return;
      }

      if (isCouponBogo(code) && cart.totalPrintsQuantity < 3) {
        throw new Error(getBogoErrorMsg(cart));
      }

      const { errors } = await addPromoCode({
        variables: { input: { id: cart.id, code: couponCode } },
      });

      if (errors) {
        throw new Error();
      }

      Analytics.track('couponApplied', cart, couponCode);
    } catch (err) {
      setError(getCouponErrorMsg(couponCode, cart));

      Sentry.captureException(err);
      Analytics.track('couponDenied', cart, couponCode);
    }
  };

  return (
    <>
      {error && <ErrorAlert error={error} code={code} />}

      <Container isOpen={true} onSubmit={onSubmit}>
        <InputContainer>
          <Input
            fullWidth={true}
            placeholder="Promo Code"
            onChange={e => setCode(e.target.value)}
            value={code}
          />
        </InputContainer>

        <ApplyButton loading={loading} type="submit" disabled={loading}>
          {loading ? 'Loading...' : 'Apply'}
        </ApplyButton>
      </Container>
    </>
  );
};

const Container = styled.form<{ isOpen: boolean }>`
  width: 100%;
  margin: 0.5rem 0 0;
  padding: 0 0 1rem;
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  flex-direction: column;

  ${media.md`
   flex-direction: row;
  `}
`;

const InputContainer = styled.div`
  flex: 1;

  > div {
    margin-bottom: 0;
  }

  input {
    height: 42px;
  }
`;

const ApplyButton = styled(Button)<{ loading?: boolean }>`
  margin: 5px 0 0;
  padding: 5px;
  background: #3a4f66;
  border: none;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  text-transform: uppercase;
  width: 100%;
  height: 42px;
  flex-basis: 40px;
  line-height: 32px;

  ${media.md`
    flex-basis: 120px;
    margin-left: 8px;
    height: 41px;
  `}

  &:hover {
    background: #3a4f66;
  }
`;
