import React from 'react';
import Image from 'next/image';
import * as Sentry from '@sentry/browser';
import * as Analytics from '@app/lib/analytics';
import { Cart } from '@app/lib/graphql/models';
import { useRemoveVoucherMutation } from '@app/lib/graphql/schema';
import styled from 'styled-components';

interface BadgeProps {
  cart: Cart;
}

export const Badge: React.FC<BadgeProps> = ({ cart }) => {
  const [removeVoucher, { loading }] = useRemoveVoucherMutation();
  const code = cart.couponCode;

  const onRemoveClick = async () => {
    try {
      await removeVoucher({ variables: { input: { id: cart.id, code } } });

      Analytics.track('couponRemoved', cart, code);
    } catch (err) {
      Sentry.captureException(err);
      Analytics.track('genericError', err);
    }
  };

  return (
    <Container loading={loading}>
      <Code>{code}</Code>

      <RemoveIcon onClick={onRemoveClick}>
        <Image width={8} height={8} src="/assets/icons/close.png" alt="Close" />
      </RemoveIcon>
    </Container>
  );
};

const Container = styled.div<{ loading: boolean }>`
  margin: 0 0 10px;
  padding: 6px;
  background: #f9fafb;
  border: 1px solid #e2e7eb;
  border-bottom: 2px solid #e2e7eb;
  border-radius: 4px;
  opacity: ${props => (props.loading ? '0.3' : '1')};
  overflow: hidden;
  display: inline-block;
  text-transform: uppercase;
`;

const Code = styled.span`
  font-size: 0.8rem;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
`;

const RemoveIcon = styled.span`
  padding: 0 0 0 8px;
  cursor: pointer;
  float: right;
  margin-top: -1px;

  img {
    opacity: 0.5;
    margin-left: 5px;
    margin-right: 5px;
  }
`;
