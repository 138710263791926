import React, { memo } from 'react';
import {
  ErrorAlert,
  InfoAlert,
  SuccessAlert,
  WarningAlert,
} from './Alert.styles';

export interface AlertProps {
  isClickable?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  style?: any;
  variant?: 'error' | 'info' | 'success' | 'warning';
}

export const Alert: React.FC<AlertProps> = memo(
  ({
    children,
    isClickable = false,
    onClick = () => {},
    style = {},
    variant = 'info',
  }) => {
    let Alert;

    switch (variant) {
      case 'info':
        Alert = InfoAlert;
        break;
      case 'success':
        Alert = SuccessAlert;
        break;
      case 'error':
        Alert = ErrorAlert;
        break;
      case 'warning':
        Alert = WarningAlert;
        break;
      default:
        Alert = InfoAlert;
    }

    return (
      <Alert style={style} isClickable={isClickable} onClick={onClick}>
        {children}
      </Alert>
    );
  }
);
