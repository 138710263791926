export const Logo = (): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 684.3 74.5"
  >
    <g id="Layer_1">
      <g>
        <path
          d="M36.9,73.7C14.6,73.7,1,57.9,1,37.8c0-20,13.6-37,35.9-37s35.9,17,35.9,37C72.7,57.9,59.1,73.7,36.9,73.7z M36.9,2.1
			c-12,0-15,8-15,35.7c0,27.8,3,34.6,15,34.6c11.9,0,14.8-6.9,14.8-34.6C51.7,10.1,48.8,2.1,36.9,2.1z"
        />
        <path
          d="M129.2,26.2v1.3c-4.4,0-8,1.9-13.8,16.1l-11.7,30.2H100c-8.9-19.4-19.3-40.5-19.3-40.5c-2.6-5.3-5.7-5.7-7.6-5.8v-1.3
			h27.8v1.3c-6.3,0-2.1,8-2.1,8l10.1,20.8c0,0,4.2-10.6,5-13c0.9-2.4,4.3-15.8-6.4-15.8v-1.3H129.2z"
        />
        <path
          d="M140.2,48.6c0.4,12.6,5.4,17.2,12.1,18.7c5.3,1.2,12.7-1.3,14.9-8l1.3,0.5c-1.4,6.4-8.1,13.9-21.2,13.9
			c-13.2,0-23.1-11.3-23.1-24c0-15.8,11.4-24.4,24.5-24.4c12.7,0,21.2,6,21.2,23.3H140.2z M156.5,47.3c0-12.7-2.7-20.7-7.8-20.7
			c-5.4,0-8.6,11-8.6,20.7H156.5z"
        />
        <path
          d="M202.9,40.2c-1.8-1.5-2.8-3.5-2.8-5.6c0-1.2-3.6-0.4-7.6,5.8V61c0,5.6,0.5,9.6,4.1,10.6v1.2h-23.1v-1.2
			c3.9-1.2,4-5.5,4-10.6V37.5c0-3.5-1.1-4.9-5.3-6.8v-1.3l20.2-4.1v12.8c6.3-9.6,9.8-11,12.5-12.2c2.7-1.2,6.6-0.6,9,1.5
			c3.5,3,3.8,8.5,0.8,11.9C211.7,42.8,206.4,43.3,202.9,40.2z"
        />
        <path
          d="M258.8,73.7c-22.2,0-35.9-15.9-35.9-36c0-20,13.6-37,35.9-37s35.9,17,35.9,37C294.7,57.9,281.1,73.7,258.8,73.7z
			 M258.8,2.1c-12,0-15,8-15,35.7c0,27.8,3,34.6,15,34.6c11.9,0,14.8-6.9,14.8-34.6C273.7,10.1,270.8,2.1,258.8,2.1z"
        />
        <path
          d="M353.1,71l-17.2,2.6c-2.7,0.4-2.8-1.9-2.8-1.9v-8.6c-1.6,5.6-8.3,10.6-15.7,10.6c-8,0-14.5-3.3-14.5-15.5v-22
			c0-6.1,0.2-7-5.7-7.8v-1.2l20.6-2V58c0,5.7,2,8.4,7.6,8.4c2.8,0,6.1-1.9,7.7-6.1V36.6c0-6.2,0.4-7.5-4.7-8.1v-1.2l19.6-2v38.5
			c0,4.8,0.9,5.6,5.2,6.1V71z"
        />
        <path
          d="M383.6,40.2c-1.8-1.5-2.8-3.5-2.8-5.6c0-1.2-3.6-0.4-7.6,5.8V61c0,5.6,0.5,9.6,4.1,10.6v1.2h-23.1v-1.2
			c3.9-1.2,4-5.5,4-10.6V37.5c0-3.5-1.1-4.9-5.3-6.8v-1.3l20.2-4.1v12.8c6.3-9.6,9.8-11,12.5-12.2c2.7-1.2,6.6-0.6,9,1.5
			c3.5,3,3.8,8.5,0.8,11.9C392.4,42.8,387.1,43.3,383.6,40.2z"
        />
        <path
          d="M491.1,72.8h-30.7v-1.3c4.5,0,6.3-2.6,6.3-9.5V5.5l-26.6,68.3h-1.3L408.3,13v33.6c0,6.6,1.8,24.8,14.6,24.8v1.3h-31.1
			v-1.3c13,0,15.1-18.2,15.1-24.8V10.9c-3.8-6.9-8-7.7-10.7-7.9V1.7h27.2l24.3,48.9l18.9-48.9h23.4V3c-4.6,0-6.3,2-6.3,8.2V62
			c0,6.9,2.6,9.5,7.3,9.5V72.8z"
        />
        <path
          d="M515.8,73.7c-16.7,0-25-11-25-24.2c0-13.3,8.4-24.2,25-24.2c16.8,0,25,10.9,25,24.2C540.8,62.7,532.6,73.7,515.8,73.7z
			 M515.8,26.6c-6.4,0-7.4,4.6-7.4,22.9s1,22.9,7.4,22.9c6.4,0,7.4-4.6,7.4-22.9S522.2,26.6,515.8,26.6z"
        />
        <path
          d="M570.9,73.7c-16.7,0-25-11-25-24.2c0-13.3,8.4-24.2,25-24.2c16.8,0,25,10.9,25,24.2C596,62.7,587.7,73.7,570.9,73.7z
			 M570.9,26.6c-6.4,0-7.4,4.6-7.4,22.9s1,22.9,7.4,22.9c6.4,0,7.4-4.6,7.4-22.9S577.3,26.6,570.9,26.6z"
        />
        <path
          d="M651,72.8h-23v-1.2c3.8-1.1,4-5.6,4-10.7V41c0-5.4-2.6-8.5-7.6-8.5c-3.1,0-6.6,3-7.3,6.2v22.4c0,5.6,0.5,9.5,4.1,10.5v1.2
			h-23v-1.2c3.8-1.1,3.9-5.4,3.9-10.5V37.4c0-3.5-1-4.8-5.2-6.7v-1.3l20.1-4.1v10.3c2.4-5.8,9.1-10.3,15-10.3
			c8,0,14.6,3.2,14.6,15.4v20.3c0,5.6,0.5,9.8,4.1,10.7V72.8z"
        />
        <path
          d="M658.8,2.1v4.6h-0.7c-0.1-3.5-4-3.9-4.6-3.9h-0.4v12c0,1.5,0.5,2.3,1.4,2.3v0.7h-6.8v-0.7c0.9,0,1.4-0.8,1.4-2.3v-12h-0.5
			c-0.5,0-4.5,0.4-4.5,3.9h-0.7V2.1H658.8z M682.1,17.9h-6.8v-0.7c1,0,1.4-0.4,1.4-1.9V4.3l-5.4,13.7h-0.8l-6.5-13.2v7
			c0,1.5,0.2,5.3,3,5.3v0.7h-6.9v-0.7c2.9,0,3.1-3.8,3.1-5.3V3.9c-0.7-1.1-1.6-1.1-2.1-1.1V2.1h6l5.3,10.8l4.2-10.8h5.2v0.7
			c-1,0-1.4,0.5-1.4,1.9v10.6c0,1.5,0.6,1.9,1.6,1.9V17.9z"
        />
      </g>
    </g>
    <g id="Layer_2"></g>
    <g id="Layer_3"></g>
  </svg>
);
