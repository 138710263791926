import * as React from 'react';
import styled from 'styled-components';

const US_ICON = require('./images/usa.png');
const CARBON_FREE_BADGE = require('./images/carbon-free.png');

export const WhyChooseUs: React.FC = () => {
  return (
    <Container>
      <Title>Why Choose Us</Title>

      <USALocation>
        <Emblem>
          <img src={US_ICON} alt="United States" />
        </Emblem>
        <MoneyBackDescription>
          <h3>Located in the United States!</h3>
          <p>We are located in sunny San Diego, California!</p>
        </MoneyBackDescription>
      </USALocation>

      <USALocation>
        <Emblem>
          <img src={CARBON_FREE_BADGE} alt="Carbon Free Badge" />
        </Emblem>
        <MoneyBackDescription>
          <h3>
            We&apos;ve joined Carbon<span>free</span> &reg;!
          </h3>
          <p>
            We love our planet which is why we joined the Carbonfree&reg;
            program. Every year we buy carbon offsets in assurance that we
            remain carbon neutral!
          </p>
        </MoneyBackDescription>
      </USALocation>
    </Container>
  );
};

const Title = styled.h2`
  margin: 0 0 1.6em 0.5em;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
`;

const Emblem = styled.div`
  display: block;
  flex: 0;
  flex-basis: 50px;
  width: 50px;
  height: 50px;
  margin-right: 22px;

  img {
    width: 100%;
    height: auto;
  }
`;

const USALocation = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #e2e3e7;
  padding: 1.2em 0;
  margin-top: 1.2em;
`;

const MoneyBackDescription = styled.div`
  text-align: left;
  color: #59656f;
  flex: 1;

  h3 {
    font-size: 1em;
    margin: 0;
    padding: 0;

    span {
      font-style: italic;
      font-weight: 100;
    }
  }

  p {
    font-size: 0.7em;
    margin: 8px 0 0;
  }
`;

const Container = styled.div`
  margin: 1em 0;
  padding: 1rem;
`;

export default WhyChooseUs;
